import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import Home from "./components/home/home";
import AboutUs from "./components/about-us/aboutus";
import ScrollToTop from "./components/scroll";
import Project from "./components/project/project";
import AddProjectForm from "./components/admin/projects/addProject";
import ContactUs from "./components/contact-us/contact-us";
import ProjectDetails from "./components/project/projectDetails";
import { StyledEngineProvider } from "@mui/material";
import BottomRightSpeedDial from "./components/common/speedDial";
import AdminDashboard from "./components/admin/dashboard";
import Adminlogin from "./components/admin/login";
import AdminHome from "./components/admin/adminHome";
import Departments from "./components/admin/departments/departments";
import Projects from "./components/admin/projects/projects";
import AddDepartment from "./components/admin/departments/addDepartment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Discount from "./components/admin/projects/discount";
import PromotionalBanner from "./components/home/banner";

import { getBanner } from "./services/bannerService";
import { useEffect, useState } from "react";
import Banners from "./components/admin/banner/banners";
import AddBanner from "./components/admin/banner/addBanner";
import Trainings from "./components/admin/trainings/trainings";
import Assignments from "./components/admin/assignments/assignments";
import DiscountBanner from "./components/common/discountBanner";
import Testimonials from "./components/admin/testimonials/testimonials";
import AddTestimonial from "./components/admin/testimonials/addTestimonial";

function App() {
    const [banner, setBanner] = useState(null);
    const [openBanner, setOpenBanner] = useState(false);

    useEffect(() => {
        fetchBanner();
    }, []);

    const fetchBanner = async () => {
        getBanner()
            .then((res) => {
                setBanner(res.data.data);
                setOpenBanner(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <BrowserRouter>
            <ToastContainer
                position='top-right'
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='dark'
                style={{
                    borderRadius: "0px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Poppins, sans-serif",
                    zIndex: 9999,
                }}
            />
            <ScrollToTop />
            {banner && <PromotionalBanner open={openBanner} handleClose={() => setOpenBanner(false)} bannerData={banner} />}
            {/* <Header /> */}
            <StyledEngineProvider injectFirst>
                <BottomRightSpeedDial />
            </StyledEngineProvider>
            {/* <DiscountBanner /> */}
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='about-us' element={<AboutUs />} />
                <Route path='projects' element={<Project />} />
                <Route path='project/:id' element={<ProjectDetails />} />
                <Route path='contact-us' element={<ContactUs />} />
                <Route path='admin-login' element={<Adminlogin />} />
                <Route path='admin' element={<AdminHome />}>
                    <Route path='dashboard/tab=0' element={<AdminDashboard />} />
                    <Route path='departments/tab=1' element={<Departments />}>
                        <Route path='add' element={<AddDepartment mode={"add"} />} />
                        <Route path='view/:id' element={<AddDepartment mode={"view"} />} />
                    </Route>
                    <Route path='projects/tab=2' element={<Projects />}>
                        <Route path='add' element={<AddProjectForm mode={"add"} />} />
                        <Route path='view/:id' element={<AddProjectForm mode={"view"} />} />
                    </Route>
                    <Route path='discount/tab=3' element={<Discount />} />
                    <Route path='banners/tab=4' element={<Banners />}>
                        <Route path='add' element={<AddBanner mode={"add"} />} />
                        <Route path='view/:id' element={<AddBanner mode={"view"} />} />
                    </Route>
                    <Route path='trainings/tab=5' element={<Trainings />}>
                        {/* <Route path='add' element={<AddBanner mode={"add"} />} />
                        <Route path='view/:id' element={<AddBanner mode={"view"} />} /> */}
                    </Route>
                    <Route path='assignments/tab=6' element={<Assignments />}>
                        {/* <Route path='add' element={<AddBanner mode={"add"} />} />
                        <Route path='view/:id' element={<AddBanner mode={"view"} />} /> */}
                    </Route>
                    <Route path='testimonials/tab=7' element={<Testimonials />}>
                        <Route path='add' element={<AddTestimonial mode={"add"} />} />
                        <Route path='view/:id' element={<AddTestimonial mode={"view"} />} />
                    </Route>
                </Route>
            </Routes>
            {/* <Footer />   */}
        </BrowserRouter>
    );
}

export default App;
