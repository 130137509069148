import * as React from "react";
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Link } from "react-router-dom";

export default function BottomRightSpeedDial() {
    const actions = [
        { icon: <YouTubeIcon color='error' />, name: "YouTube", link: "https://youtube.com/@techlogics552?si=n3Kj35lp_TUhbcdA" },
        { icon: <InstagramIcon color='primary' />, name: "Instagram", link: "https://www.instagram.com/techlogics39/" },
        { icon: <WhatsAppIcon color='success' />, name: "WhatsApp", link: "https://wa.me/9133181444" },
    ];

    return (
        <SpeedDial
            ariaLabel='SpeedDial example'
            sx={{
                position: "fixed",
                bottom: 20,
                right: 20   ,
                "& .MuiFab-primary": {
                    backgroundColor: "#e5057f", // Change SpeedDial button color
                    // color: "#aaa", // Change SpeedDial icon color
                },
                "& .MuiSpeedDialIcon-icon": {
                    color: "#fff", // Change the inner SpeedDial icon color
                },
            }}
            icon={<SpeedDialIcon  />}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    sx={{ pt: 1 }}
                    key={action.name}
                    icon={
                        action.link ? (
                            <Link to={action.link} target='_blank' rel='noopener noreferrer'>
                                {action.icon}
                            </Link>
                        ) : (
                            action.icon
                        )
                    }
                    tooltipTitle={action.name}
                />
            ))}
        </SpeedDial>
    );
}
