import { Box, Button, Card, CardMedia, Chip, Grid, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import CodeIcon from "@mui/icons-material/Code";
import CategoryIcon from "@mui/icons-material/Category";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useTheme } from "@emotion/react";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PaymentDialog from "./paymentDialog";

function ProjectDetailBanner({ project, image }) {
    const priceStyles = {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        padding: "6px 12px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        fontWeight: "bold",
        color: "#242f62",
        border: "1px solid #242f62",
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getFinalPrice = (project) => {
        if (project.discountType === "percentage") {
            return Number.parseFloat(project.price - (project.price * project.discountPrice) / 100).toFixed(1);
        } else {
            return project.price - project.discountPrice;
        }
    };

    return (
        <Box className='project-detail-banner'>
            <PaymentDialog open={open} handleClose={handleClose} project={project} />
            <Box
                className='project-detail-banner-text'
                p={isMobile ? 0 : 4}
                sx={{ display: "flex", flexDirection: { xs: "column-reverse", md: "row" }, gap: 2 }}
            >
                <Grid p={isMobile ? 2 : 2} sx={{ flexBasis: "57%", alignItems: "center" }} container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant={isMobile ? "h6" : "h4"} gutterBottom>
                            {project?.title}
                        </Typography>
                        <Stack direction='row' alignItems='center' spacing={1} mb={2}>
                            <Chip label={`${project?.project_code}`} sx={{ backgroundColor: "#e5057f", color: "white", mb: 2 }}></Chip>
                            <Stack direction='row' spacing={0.5} sx={priceStyles}>
                                <CodeIcon sx={{ fontSize: "20px", color: "#42a5f5" }} />
                                <Typography variant='body1' sx={{ fontWeight: "bold", fontSize: { xs: "12px", sm: "18px" } }}>
                                    {project?.category}
                                </Typography>
                            </Stack>
                            <Stack direction='row' spacing={0.5} sx={priceStyles}>
                                <CategoryIcon sx={{ fontSize: "20px", color: "#42a5f5" }} />
                                <Typography variant='body1' sx={{ fontWeight: "bold", fontSize: { xs: "12px", sm: "18px" } }}>
                                    {project?.technology}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Box sx={{ display: "flex", flexDirection: { xs: "row", md: "row" }, gap: 1 }}>
                            <Button
                                onClick={handleClickOpen}
                                fullWidth={isMobile ? true : false}
                                variant='contained'
                                size={isMobile ? "small" : "large"}
                                sx={{ backgroundColor: "#e5057f" }}
                            >
                                Buy now
                                {/* Buy for <CurrencyRupeeIcon sx={{ fontSize: "20px" }} /> {getFinalPrice(project)} */}
                            </Button>
                            <Tooltip title='Download Abstract'>
                                <Button
                                    fullWidth={isMobile ? true : false}
                                    href={project?.documentLink}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    variant='outlined'
                                    size={isMobile ? "small" : "large"}
                                    // sx={{ color: {xs: 'black', md: 'white'}, borderColor: {xs: 'black', md: 'white'} }}
                                    sx={{ color: "white", borderColor: "white" }}
                                >
                                    <CloudDownloadIcon color='' sx={{ fontSize: "20px", mr: 1 }} /> Abstract
                                </Button>
                            </Tooltip>
                            {!image && (
                                <Tooltip title='YouTube Channel'>
                                    <Button
                                        href='https://www.youtube.com/@techlogics552'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        fullWidth={isMobile ? true : false}
                                        variant='outlined'
                                        size={isMobile ? "small" : "large"}
                                        sx={{ color: "white", borderColor: "white" }}
                                    >
                                        <YouTubeIcon color='error' sx={{ mr: 1 }} /> Visit
                                    </Button>
                                </Tooltip>
                            )}
                        </Box>
                    </Grid>
                </Grid>
                {image && !isMobile && (
                    <Card sx={{ flexBasis: "43%" }}>
                        <CardMedia component='iframe' height='300' image={`https://www.youtube.com/embed/${image}`} title={project?.title} />
                    </Card>
                )}
            </Box>
        </Box>
    );
}

export default ProjectDetailBanner;
