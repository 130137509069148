import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ArticleIcon from "@mui/icons-material/Article";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useTheme } from "@emotion/react";
import { useMediaQuery, Tooltip, Box } from "@mui/material";
import { useNavigate } from "react-router";

export default function ProjectCard(props) {
    const { project } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const [showMore, setShowMore] = React.useState(false);

    // Responsive styling for mobile and desktop
    const cardStyles = {
        cursor: "pointer",
        // padding: "10px",
        margin: { xs: "12px 18px", sm: "12px 5px" },
        maxWidth: "100%",
        // maxHeight: "400px", // Set a fixed height for the card
        height: "auto",
        borderRadius: "12px",
        transition: "transform 0.2s ease-in-out",
        border: '1px solid #cacaca',
        "&:hover": {
            transform: "scale(1.02)",
            boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
        },
    };

    const priceStyles = {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        padding: "6px 8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        border: "1px solid #cacaca",
        fontWeight: "bold",
        color: "#242f62",
    };

    const getFinalPrice = (project) => {
        if (project.discountType === "percentage") {
            return Number.parseFloat(project.price - (project.price * project.discountPrice) / 100).toFixed(1);
        } else {
            return project.price - project.discountPrice;
        }
    };

    return (
        <Card sx={cardStyles} elevation={3} onClick={() => navigate(`/project/${project.id}`)}>
            <CardMedia
                component='img'
                height='220'
                image={require(`../../assets/images/${project.department}.png`)}
                alt='Project Image'
                sx={{ borderRadius: "12px 12px 0 0" }}
            />
            <CardContent sx={{ paddingBottom: "8px", overflow: "hidden" }}>
                <Tooltip title={project.title} arrow>
                    <Typography
                        variant={isMobile ? "body1" : "h6"}
                        sx={{
                            fontWeight: "600",
                            lineHeight: "1.3",
                            color: "#333",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: showMore ? "unset" : 3, // Limits to 3 lines unless "Read More" is clicked
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {project.title}
                    </Typography>
                </Tooltip>

                <Stack direction='row' alignItems='center' spacing={1} sx={{ mt: 1 }}>
                    <Chip
                        label={project.technology}
                        sx={{
                            backgroundColor: "#f0f4ff",
                            color: "#242f62",
                            fontWeight: "bold",
                        }}
                    />
                    <Box flexGrow={1} />
                    {project.discountType && (
                        <Stack direction='row' spacing={0.5} sx={{ textDecoration: "line-through", color: "grey.500", display: "flex", alignItems: "center" }}>
                            <CurrencyRupeeIcon sx={{ fontSize: "18px", color: "#9e9e9e" }} />
                            <Typography variant='body1' sx={{ fontWeight: "bold", fontSize: "18px" }}>
                                {project.price}
                            </Typography>
                        </Stack>
                    )}
                    {/* <Stack direction='row' spacing={0.5} sx={priceStyles}>
                        <CurrencyRupeeIcon sx={{ fontSize: "22px", color: "#42a5f5" }} />
                        <Typography variant='body1' sx={{ fontWeight: "bold", fontSize: "20px" }}>
                            {getFinalPrice(project)}
                        </Typography>
                    </Stack> */}
                </Stack>
            </CardContent>
            <CardActions disableSpacing sx={{ justifyContent: "space-between" }}>
                <Tooltip title='View YouTube Video' placement='right'>
                    <IconButton onClick={() => window.open(project.youtubeLink, "_blank")}>
                        <YouTubeIcon sx={{ color: "#FF0000" }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title='View Project Abstract' placement='left'>
                    <IconButton onClick={() => window.open(project.documentLink, "_blank")}>
                        <ArticleIcon sx={{ color: "#3f51b5" }} />
                    </IconButton>
                </Tooltip>
            </CardActions>
        </Card>
    );
}
