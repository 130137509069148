import { useTheme } from "@emotion/react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import TestimonialCard from "./testimonialCard";
import Slider from "react-slick";

export default function Testimonials({ testimonials }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    
    const settings = {
        dots: true, // Display dots navigation below the carousel
        infinite: true, // Infinite loop
        speed: 500, // Transition speed in milliseconds
        slidesToShow: 4, // Number of slides to show at a time
        slidesToScroll: 2, // Number of slides to scroll at a time
        autoplay: true, // Enable automatic sliding
        autoplaySpeed: 2000, // Slide every 3 seconds
        responsive: [
            {
                breakpoint: 1024, // Below this breakpoint (e.g., tablets), show 2 cards
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 600, // Below this breakpoint (e.g., mobile), show 1 card
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true, // Enable centering the card on mobile
                    centerPadding: "16px",
                },
            },
        ],
    };
    return (
        <Box p={1}>
            <Typography variant={isMobile ? "h5" : "h4"} align='center' color='#e5057f' gutterBottom>
                What our customers say
            </Typography>
            <Slider {...settings}>
                {testimonials.map((testimonial, index) => (
                    <TestimonialCard key={index} testimonial={testimonial} />
                ))}
            </Slider>
            {/* <div src='https://cdn.trustindex.io/loader.js?adf17fc365cf751683061c8a6a6'></div> */}
            {/* <div id='trustindex-widget' className='trustindex-widget-container'></div> */}
        </Box>
    );
}
