import React from "react";
import { Dialog, DialogContent, IconButton, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

const PromotionalBanner = ({ open, handleClose, bannerData }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth='xs' // Set to xs or control based on the desired width
            sx={{
                "& .MuiDialog-paper": {
                    margin: 0, // Remove default margin around the dialog
                    overflow: "hidden", // Ensure no overflow
                    maxWidth: isMobile ? "100%" : "40%", // Full width on mobile
                },
            }}
        >
            <DialogContent sx={{ p: 0, position: "relative" }}>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        zIndex: 1,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <img
                    src={bannerData.image}
                    alt={bannerData.title}
                    style={{
                        width: "100%", // Full width
                        height: "auto", // Maintain aspect ratio
                        display: "block", // Avoids inline-block whitespace issues
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

export default PromotionalBanner;
