import React from "react";
import Slider from "react-slick";
import { Card, CardContent, CardMedia, Typography, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import academicImage from "../../assets/images/academic.jpg";
import abroadImage from "../../assets/images/abroad.jpg";
import dissertationImage from "../../assets/images/dissertation.jpg";
import publicationImage from "../../assets/images/publication.jpg";
import assignmentImage from "../../assets/images/assignment.jpg";
import internshipImage from "../../assets/images/internship.jpg";
import trainingImage from "../../assets/images/training.jpg";
// Sample services data
const services = [
    {
        title: "Projects",
        description: "High-quality academic projects tailored for students.",
        image: academicImage, // Replace with real image URLs
    },
    // {
    //     title: "Abroad Projects",
    //     description: "Get expert guidance on projects while studying abroad.",
    //     image: abroadImage, // Replace with real image URLs
    // },
    {
        title: "Dissertations",
        description: "Professional assistance for writing and reviewing dissertations.",
        image: dissertationImage, // Replace with real image URLs
    },
    {
        title: "Publications",
        description: "Publish your work in recognized journals with our support.",
        image: publicationImage, // Replace with real image URLs
    },
    {
        title: "Assignments",
        description: "Help with assignments to achieve academic success.",
        image: assignmentImage, // Replace with real image URLs
    },
    {
        title: "Internships",
        description: "Get hands-on experience with internships tailored to your field.",
        image: internshipImage, // Replace with real image URLs
    },
    {
        title: "Training",
        description: "Enhance your skills with our comprehensive training programs.",
        image: trainingImage,
    },
];

const WhatWeOfferCarousel = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile view
    const settings = {
        dots: true, // Display dots navigation below the carousel
        infinite: true, // Infinite loop
        speed: 500, // Transition speed in milliseconds
        slidesToShow: 6, // Number of slides to show at a time
        slidesToScroll: 2, // Number of slides to scroll at a time
        autoplay: true, // Enable automatic sliding
        autoplaySpeed: 2000, // Slide every 3 seconds
        responsive: [
            {
                breakpoint: 1024, // Below this breakpoint (e.g., tablets), show 2 cards
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 600, // Below this breakpoint (e.g., mobile), show 1 card
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    return (
        <Box sx={{ padding: 1 }}>
            <Typography variant={isMobile ? "h5" : "h4"} align='center' color='#e5057f' gutterBottom>
                Our Services
            </Typography>

            <Slider {...settings}>
                {services.map((service, index) => (
                    <Box key={index} className='carousal-card'>
                        <Card
                            sx={{
                                // backgroundImage: "linear-gradient(0deg, rgb(176, 207, 255),rgb(92, 104, 168))",
                                borderRadius: "8px",
                                overflow: "hidden",
                            }}
                        >
                            <CardMedia
                                component='img'
                                height='140'
                                image={service.image}
                                alt={service.title}
                                sx={{
                                    objectFit: "cover",
                                    filter: "brightness(0.8)",
                                }}
                            />
                            <CardContent>
                                <Typography color='#242f62' variant={isMobile ? "body2" : "h6"} component='div'>
                                    {service.title}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                ))}
            </Slider>
        </Box>
    );
};

export default WhatWeOfferCarousel;
