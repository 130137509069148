import React, { useEffect, useState } from "react";
import { Button, Box, useMediaQuery, Typography, InputAdornment, OutlinedInput, Divider, FormControlLabel, Switch, Stack } from "@mui/material";
import * as yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useParams } from "react-router";
import { getDepartments, getDepartmentByCode } from "../../../services/departmentService";
import { getProjectById, addProject, updateProject, deleteProject } from "../../../services/projectService";
import { useTheme } from "@emotion/react";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ArticleIcon from "@mui/icons-material/Article";
import Loader from "../../common/loader";
import { toast } from "react-toastify";

const projectSchema = yup.object().shape({
    title: yup.string().required("Required"),
    department: yup.string().required("Required"),
    description: yup.string().required("Required"),
    youtubeLink: yup.string().url().required("Required"),
    documentLink: yup.string().url().required("Required"),
    price: yup.number().required("Required").min(0, "Price must be a positive number"),
    category: yup.string().required("Required"),
    technology: yup.string().required("Required"),
});

const ProjectForm = ({ mode }) => {
    const { id } = useParams();
    const [departments, setDepartments] = useState([]);
    const [categories, setCategories] = useState([]);
    const [checked, setChecked] = React.useState(false);
    const [message, setMessage] = useState("");

    const [technologies, setTechnologies] = useState([]);
    const [formData, setFormData] = useState({
        title: "",
        department: "",
        description: "",
        youtubeLink: "",
        documentLink: "",
        price: "",
        category: "",
        technology: "",
    });

    const handleSwitchChange = (event) => {
        setChecked(event.target.checked);
    };

    const TECHNOLOGIES = process.env.REACT_APP_PROJECT_TECHNOLOGIES;
    useEffect(() => {
        setErrors({});
        setChecked(false);
        setTechnologies(TECHNOLOGIES.split(","));
        getDepartments()
            .then((res) => {
                setDepartments(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
        if (mode === "add") {
            setFormData({
                title: "",
                department: "",
                description: "",
                youtubeLink: "",
                documentLink: "",
                price: "",
                category: "",
                technology: "",
            });
        } else if (mode === "view") {
            getProjectById(id)
                .then((res) => {
                    getDepartmentByCode(res.data.data.department).then((res) => {
                        setCategories(res.data.data.categories);
                    });
                    setFormData(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [id]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile view

    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDeptChange = (value) => {
        const dept = departments.find((d) => d.code === value); // Find the selected department by code
        setCategories(dept.categories || []); // Update the categories for the selected department
        setFormData({ ...formData, department: dept.code }); // Update the form data with the department code
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        projectSchema
            .validate(formData, { abortEarly: false })
            .then(() => {
                setIsLoading(true);
                setErrors({});
                if (mode === "add") {
                    setMessage("Saving project");
                    addProject(formData)
                        .then(() => {
                            toast.success("Project added");
                            setTimeout(() => {
                                setIsLoading(false);
                                window.location.href = "/admin/projects/tab=2";
                            }, 1500);
                        })
                        .catch((err) => {
                            console.log(err);
                            toast.error(err.response.data.message || "Failed to add project");
                            setIsLoading(false);
                        });
                } else if (mode === "view") {
                    setMessage("Updating project");
                    updateProject(id, formData)
                        .then(() => {
                            toast.success("Project updated");
                            setTimeout(() => {
                                setIsLoading(false);
                                window.location.href = "/admin/projects/tab=2";
                            }, 1500);
                        })
                        .catch((err) => {
                            console.log(err);
                            toast.error(err.response.data.message || "Failed to update project");
                            setIsLoading(false);
                        });
                }
            })
            .catch((validationErrors) => {
                const validationErrorMap = {};
                validationErrors.inner.forEach((error) => {
                    validationErrorMap[error.path] = error.message;
                });

                setErrors(validationErrorMap);
            });
    };

    const handleDeleteProject = () => {
        setIsLoading(true);
        setMessage("Deleting project");
        deleteProject(id)
            .then(() => {
                toast.success("Project deleted");
                setTimeout(() => {
                    setIsLoading(false);
                    window.location.href = "/admin/projects/tab=2";
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response.data.message || "Failed to delete project");
                setIsLoading(false);
            });
    };

    return (
        <Box>
            {isLoading && <Loader message={message} />}
            {!isLoading && (
                <>
                    {mode === "add" && (
                        <Typography className='purple' variant='h6'>
                            Add Project
                        </Typography>
                    )}
                    {mode === "view" && (
                        <>
                            <Typography className='purple' variant='h6'>
                                Project Details
                            </Typography>
                            <FormControlLabel
                                value='end'
                                control={<Switch checked={checked} onChange={handleSwitchChange} color='primary' />}
                                label='Edit mode'
                                labelPlacement='end'
                            />
                        </>
                    )}
                    <Box py={1}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <Select
                                readOnly={mode === "view" && !checked}
                                error={!!errors.department}
                                value={formData.department}
                                onChange={(e) => handleDeptChange(e.target.value)}
                                input={
                                    <OutlinedInput
                                        size='small'
                                        startAdornment={
                                            <InputAdornment position='start'>
                                                <Typography width={90} variant='body1'>
                                                    Department
                                                </Typography>
                                                <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            errors?.department && (
                                                <InputAdornment position='end'>
                                                    <Typography mr={2} variant='caption' color='error'>
                                                        {errors?.department}
                                                    </Typography>
                                                </InputAdornment>
                                            )
                                        }
                                    />
                                }
                            >
                                {departments.map((dept, key) => (
                                    <MenuItem key={key} value={dept.code}>
                                        {dept.code}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ mb: 2 }} fullWidth>
                            <Select
                                readOnly={mode === "view" && !checked}
                                error={!!errors.category}
                                name='category'
                                value={formData.category}
                                onChange={handleChange}
                                input={
                                    <OutlinedInput
                                        size='small'
                                        variant='outlined'
                                        startAdornment={
                                            <InputAdornment position='start'>
                                                <Typography width={90} variant='body1'>
                                                    Category
                                                </Typography>
                                                <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            errors?.category && (
                                                <InputAdornment position='end'>
                                                    <Typography mr={2} variant='caption' color='error'>
                                                        {errors?.category}
                                                    </Typography>
                                                </InputAdornment>
                                            )
                                        }
                                    />
                                }
                            >
                                {categories.map((category, key) => (
                                    <MenuItem key={key} value={category.name}>
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <OutlinedInput
                                size='small'
                                name='title'
                                readOnly={mode === "view" && !checked}
                                value={formData.title}
                                onChange={handleChange}
                                error={!!errors.title}
                                fullWidth
                                startAdornment={
                                    <InputAdornment position='start'>
                                        <Typography width={90} variant='body1'>
                                            Title
                                        </Typography>
                                        <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    errors?.title && (
                                        <InputAdornment position='end'>
                                            <Typography variant='caption' color='error'>
                                                {errors?.title}
                                            </Typography>
                                        </InputAdornment>
                                    )
                                }
                            />
                        </FormControl>
                        <OutlinedInput
                            sx={{ mb: 2 }}
                            readOnly={mode === "view" && !checked}
                            name='description'
                            value={formData.description}
                            onChange={handleChange}
                            error={!!errors.description}
                            fullWidth
                            multiline
                            startAdornment={
                                <InputAdornment position='start'>
                                    <Typography width={90} variant='body1'>
                                        Description
                                    </Typography>
                                    <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                </InputAdornment>
                            }
                            endAdornment={
                                errors?.description && (
                                    <InputAdornment position='end'>
                                        <Typography variant='caption' color='error'>
                                            {errors?.description}
                                        </Typography>
                                    </InputAdornment>
                                )
                            }
                            rows={4}
                        />

                        <FormControl fullWidth>
                            <OutlinedInput
                                sx={{ mb: 2 }}
                                size='small'
                                name='youtubeLink'
                                readOnly={mode === "view" && !checked}
                                placeholder='Youtube link'
                                value={formData.youtubeLink}
                                onChange={handleChange}
                                error={!!errors.youtubeLink}
                                startAdornment={
                                    <InputAdornment position='start'>
                                        <YouTubeIcon color='error' />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    errors?.youtubeLink && (
                                        <InputAdornment position='end'>
                                            <Typography variant='caption' color='error'>
                                                {errors?.youtubeLink}
                                            </Typography>
                                        </InputAdornment>
                                    )
                                }
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <OutlinedInput
                                size='small'
                                sx={{ mb: 2 }}
                                name='documentLink'
                                readOnly={mode === "view" && !checked}
                                placeholder='Abstract link'
                                value={formData.documentLink}
                                onChange={handleChange}
                                error={!!errors.documentLink}
                                startAdornment={
                                    <InputAdornment position='start'>
                                        <ArticleIcon color='primary' />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    errors?.documentLink && (
                                        <InputAdornment position='end'>
                                            <Typography variant='caption' color='error'>
                                                {errors?.documentLink}
                                            </Typography>
                                        </InputAdornment>
                                    )
                                }
                            />
                        </FormControl>
                        <Box className='row'>
                            <FormControl fullWidth>
                                <OutlinedInput
                                    size='small'
                                    sx={{ mb: 2 }}
                                    type='number'
                                    name='price'
                                    readOnly={mode === "view" && !checked}
                                    value={formData.price}
                                    onChange={handleChange}
                                    error={!!errors.price}
                                    fullWidth
                                    startAdornment={
                                        <InputAdornment position='start'>
                                            <Typography variant='body1'>Price</Typography>
                                            <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <Select
                                    readOnly={mode === "view" && !checked}
                                    error={!!errors.technology}
                                    name='technology'
                                    value={formData.technology}
                                    onChange={handleChange}
                                    input={
                                        <OutlinedInput
                                            size='small'
                                            startAdornment={
                                                <InputAdornment position='start'>
                                                    <Typography width={90} variant='body1'>
                                                        Technology
                                                    </Typography>
                                                    <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                errors?.technology && (
                                                    <InputAdornment position='end'>
                                                        <Typography mr={2} variant='caption' color='error'>
                                                            {errors?.technology}
                                                        </Typography>
                                                    </InputAdornment>
                                                )
                                            }
                                        />
                                    }
                                >
                                    {technologies.map((technology, key) => (
                                        <MenuItem key={key} value={technology}>
                                            {technology}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <Stack mt={isMobile ? 1 : 0} direction={"row"} gap={1}>
                            {mode === "add" && (
                                <Button onClick={handleSubmit} sx={{ backgroundColor: "#30369e" }} variant='contained'>
                                    save project
                                </Button>
                            )}
                            {checked && (
                                <Button onClick={handleSubmit} sx={{ color: "#30369e" }} variant='outlined'>
                                    update project
                                </Button>
                            )}
                            {mode === "view" && (
                                <Button onClick={handleDeleteProject} variant='contained' color='error'>
                                    delete
                                </Button>
                            )}
                        </Stack>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ProjectForm;
